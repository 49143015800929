/*!
 * The Main Custom Common JS (loads in footer of both the frontend and the backend)
 */

/*
 * *************************************************************************************************
 * Begin functionality for auto height adjustments.
 * *************************************************************************************************
 */

/*
 * Adjusts the height of a container element to match the height of its inner element.
 *
 * @param {string} innerContainerElementClass The CSS class of the inner element.
 * @param {string} outerContainerElementClass The CSS class of the outer element.
 */
window.autoAdjustContainerHeight = function (
	innerContainerElementClass,
	outerContainerElementClass
) {
	// Select all elements with the specified CSS class for outer containers
	const outerContainers = document.querySelectorAll(
		outerContainerElementClass
	);

	// Loop through each outer container element
	outerContainers.forEach(function (outerContainer) {
		// Find the first inner container element within the current outer container
		const innerContainer = outerContainer.querySelector(
			innerContainerElementClass
		);

		if (innerContainer) {
			// Get the computed styles of the inner container element
			const styles = window.getComputedStyle(innerContainer);

			// Calculate the total height of the outer container, including margins
			const elHeight =
				parseFloat(styles.height) + 2 * parseFloat(styles.marginTop);

			// Set the height of the outer container to match the calculated height
			outerContainer.style.height = elHeight + 'px';
		}
	});
};

/*
 * Event listener for when the DOM is loaded, used to adjust the height of the outer container.
 */
document.addEventListener('DOMContentLoaded', function () {
	// The function is globally scoped.
	// eslint-disable-next-line no-undef
	autoAdjustContainerHeight(
		'.bsu-dynamic-height-inner-container',
		'.bsu-dynamic-height-outer-container'
	);
});

/*
 * Event listener for when the window is resized, used to adjust the height of the outer container.
 */
window.addEventListener('resize', function () {
	// The function is globally scoped.
	// eslint-disable-next-line no-undef
	autoAdjustContainerHeight(
		'.bsu-dynamic-height-inner-container',
		'.bsu-dynamic-height-outer-container'
	);
});

/*
 * *************************************************************************************************
 * End functionality for auto height adjustments.
 * *************************************************************************************************
 */

/*
 * *************************************************************************************************
 * Begin functionality for accordion expanding and collapsing all accordion items
 * *************************************************************************************************
 */

/**
 * Collapes all of the accordions on load. For ADA compliance accordion items should be loaded
 * expanded by default. This JS inits them as collapsed on load. It also handles the toggle all
 * button interacion.
 */

// Get every individual accordion item button.
const everyAccordionButton = document.querySelectorAll('.accordion-button');

// Get every toggle all button for each accordion.
const everyAccordionToggleAllButton = document.querySelectorAll('.bsu-accordion-toggle-all');

// Init an empty array to be updated with element IDs that need to be collapsed.
// eslint-disable-next-line prefer-const -- push() is used to modify it.
let accordionItemsToCollapse = [];

// Update each accordion button to be as it would in a collapsed state.
everyAccordionButton.forEach((button) => {
	// Check if it is set to be expanded on load. Otherwise collapse it.
	if ('true' !== button.getAttribute('data-bsu-accordion-item-load-expanded')) {
		// Set it to be collapsed.
		button.classList.add('collapsed');
		button.setAttribute('aria-expanded', false);

		// Add the item that is controlled by this button to an array of items to be collapsed.
		accordionItemsToCollapse.push(button.getAttribute('data-mdb-target'));
	}
});

// Collapses every accordion item that corresponds to a button from the loop above.
accordionItemsToCollapse.forEach((elementId) => {
	const item = document.querySelector(elementId);
	item.classList.remove('show');
});

everyAccordionToggleAllButton.forEach((button) => {
	/**
	 * If JS is disabled these should be set to true on load. Then JS will set it to false below.
	 * This is done because if JS is enabled, all accordion items will be closed.
	 *
	 * @todo If the need arises add a check to see if EVERY accordion item has the option set to be
	 * expanded on load. That would be bad practice so for now, we are not factoring that in the JS.
	 */
	button.setAttribute('aria-expanded', 'false');
});

// Get all accordion wrappers and then add an event listener to the expand/collapse button.
document.querySelectorAll('.bsu-accordion').forEach((accordion) => {
	// Get the toggle for expanding/collapsing all accordion items.
	const toggleAllButton = accordion.querySelector('.bsu-accordion-toggle-all');

	// Create the array of all accordion buttons within the accordion in the loop.
	const accordionButtons = accordion.querySelectorAll('.accordion-button');

	// Add an event listener to open/close all accordion items.
	toggleAllButton.addEventListener('click', (event) => {
		// Get the current state of the toggle button.
		const toggleState = event.target.getAttribute('aria-expanded');

		// Init the new state for updating the aria label and status of expanded or collapsed.
		let newToggleState;

		/**
		 * Look at the current state of the toggle all button and flip it after it has been
		 * clicked. We want this conditional to set the state for after the button being
		 * clicked.
		 */
		if ('false' === toggleState) {
			event.target.innerHTML = 'Collapse All';
			newToggleState = 'true';
		} else {
			event.target.innerHTML = 'Expand All';
			newToggleState = 'false';
		}

		event.target.setAttribute('aria-expanded', newToggleState);

		// Expand all accordions.
		accordionButtons.forEach((button) => {
			/**
			 * Some accordions could have been individually toggled. Check if the aria label
			 * matches the state of the toggle all button before changing its collapsed/expanded
			 * state.
			 */
			if (button.getAttribute('aria-expanded') === toggleState) {
				// Simulating a click so Bootstrap JS can handle all of the changes for collapse.
				button.click();
			}
		});
	});
});
/*
 * *************************************************************************************************
 * End functionality for accordion expanding and collapsing all accordion items
 * *************************************************************************************************
 */
